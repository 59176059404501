<template>
  <div class="d-flex flex-column">
    <back-users-modal
        :isShowModal.sync = isShow
        :idUserDeleteModal.sync = idUserDelete
        :nameUserDeleteModal.sync = nameUserDelete
        :need-update-modal.sync = needUpdate
    ></back-users-modal>
    <h5 class="h5-text mb-2">Редактировать партнера</h5>
    <div class="page-block flex-column mb-6">
      <div class="mb-10 d-flex flex-wrap">
        <v-btn color="secondary" outlined :to="`/admin/partners/${categoryId}/${partnerId}/edit`" class="mr-3 mb-1 white--text">Редактировать партнера</v-btn>
        <v-btn
                color="secondary"
               :to="`/admin/partners/${categoryId}/${partnerId}/back-users`"
               class="mr-3 mb-1 white--text"
        >
          Сотрудники бека
        </v-btn>
        <v-btn
            v-if="categoryId == 2"
            color="secondary" outlined :to="`/admin/partners/${categoryId}/${partnerId}/subscription`" class="mb-1 white--text">Информация о подписке</v-btn>
      </div>
      <v-form v-model="formValid" ref="backUsersForm">
      <template
          v-for="(backUser, index) in backUsersTable"
      >
        <div  :key="index">
          <v-row>
            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="backUser.surname"
                  placeholder="Фамилия"
                  label="Фамилия"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="backUser.name"
                  placeholder="Имя"
                  label="Имя*"
                  :rules="[rules.required, rules.min]"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="4"
            >
              <v-text-field
                  v-model="backUser.position"
                  name="position"
                  placeholder="Должность"
                  label="Должность"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="backUser.phone"
                  placeholder="Телефон"
                  label="Телефон"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="backUser.email"
                  placeholder="Email"
                  label="Email"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="4"
            >
              <div class="d-flex"
              >
                <v-btn
                    v-if="index === backUsersTable.length - 1"
                    :class="{ 'mr-4': backUsersTable.length > 1}"
                    outlined
                    color="secondary"
                    x-large
                    width="200"
                    depressed
                    @click="add()"
                >
                  + еще полей
                </v-btn>

                <v-btn
                    v-if="index === backUsersTable.length - 1 && backUsersTable.length > 1"
                    outlined
                    color="error"
                    x-large
                    width="200"
                    depressed
                    @click="del(index)"
                >
                  Удалить поля
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row
              class="mb-8"
              v-if="backUsersTable.length > 1"
          >
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </div>

      </template>

      <div class="text-center">
        <v-btn
          color="secondary"
          x-large
          width="250"
          depressed
          class="mx-auto"
          @click="save()"
        >
          Сохранить
        </v-btn>
      </div>
      </v-form>
    </div>
    <div class="d-flex flex-column">
      <h5 class="h5-text mb-2">Сотрудники бека</h5>
      <div class="page-block flex-column mb-6">
          <v-row>
            <v-col
                cols="12"
                sm="3"
            >
              Фамилия
            </v-col>
            <v-col
                cols="12"
                sm="2"
            >
              Имя
            </v-col>
            <v-col
                cols="12"
                sm="2"
            >
              Телефон
            </v-col>
            <v-col
                cols="12"
                sm="2"
            >
              Email
            </v-col>
            <v-col
                cols="12"
                sm="2"
            >
              Должность
            </v-col>
            <v-col
                cols="12"
                sm="1"
            >

            </v-col>
          </v-row>
          <template
              v-for="backUser in this.backUsersDb"

          >
            <div   :key="backUser.id">
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    sm="3"
                >
                  {{backUser.surname}}
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                >
                  {{backUser.name}}
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                >
                  {{backUser.phone}}
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                >
                  {{backUser.email}}
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                >
                  {{backUser.position}}
                </v-col>
                <v-col
                    cols="12"
                    sm="1"
                >
                  <v-btn
                      class="ma-2"
                      text
                      icon
                      color="blue lighten-2"
                      @click="delUser(backUser.id, backUser.name)"
                  >
                    <v-icon color="red">mdi-delete-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
      </div>
    </div>
  </div>

</template>

<script>
  // import { Http } from '@/apis/Http'

  import backUser from '../../../apis/BackUser'

  import BackUsersModal from '../../Admin/Partners/components/BackUsersModal.vue'

  export default {
    components: {
      BackUsersModal
    },

    data() {
      return {
        partnerId: 0,
        categoryId: 0,
        backUsersDb: [],
        backUsersTable: [
          {
            surname: '',
            name: '',
            position: '',
            phone: '',
            email: ''
          }
        ],
        formValid: false,
        rules: {
          required: value => !!value || 'Обязательное.',
          min: v => v.length >= 3 || 'Минимально 3 символа'
        },
        isShow: false,
        idUserDelete: 0,
        nameUserDelete: null,
        needUpdate: false
      }
    },
    watch: {
      needUpdate: function (val) {
        console.log(val)
        if (val) {
          this.getUsers()
          this.needUpdate = false
        }
      }
    },
    mounted() {
      this.$refs.backUsersForm.validate()
    },
    async created() {
      if (this.$router.currentRoute.params.id) {
        this.partnerId = this.$router.currentRoute.params.id
      }
      if (this.$router.currentRoute.params.categoryId) {
        this.categoryId = this.$router.currentRoute.params.categoryId
      }
      this.getUsers()
    },
    methods: {
      save() {
        if (this.formValid) {
          backUser.saveUsers(this.backUsersTable, this.categoryId, this.partnerId)
          .then(({ data }) => {
            if (data.success === true) {
              this.showSuccessSystemNotification(data.msg)
              this.backUsersTable = [{
                surname: '',
                name: '',
                position: '',
                phone: '',
                email: ''
              }]
              this.getUsers()
            } else {
              this.showErrorSystemNotification(data.msg)
            }
          })
          .catch(() => {
            const msg = 'Что-то пошло не так'
            this.showErrorSystemNotification(msg)
          })
        } else {
          this.$refs.backUsersForm.validate()
        }
      },

      add() {
        this.backUsersTable.push({
          surname: '',
          name: '',
          position: '',
          phone: '',
          email: ''
        })
      },

      del(index) {
        this.backUsersTable.splice(index, 1)
      },

      getUsers() {
        backUser.getUsers(this.categoryId, this.partnerId)
        .then(({ data }) => {
          if (data.success === true) {
            this.backUsersDb = data.result
            // this.showSuccessSystemNotification(data.msg)
          } else {
            // this.showErrorSystemNotification(data.msg)
          }
        })
        .catch(() => {
          const msg = 'Что-то пошло не так'
          this.showErrorSystemNotification(msg)
        })
      },

      delUser(userId, userName) {
        this.isShow = true
        this.idUserDelete = userId
        this.nameUserDelete = userName
      }
    }
  }
</script>
