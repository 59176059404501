import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'

export default {
  saveUsers(backUsersTable: any, categoryId: any, partnerId: any) {
    return Http.post('/admin/save-back-users/', {
      backUsersTable, categoryId, partnerId
    })
  },
  getUsers(categoryId: any, partnerId: any) {
    return Http.post('/admin/get-back-users/', {
      categoryId, partnerId
    })
  },
  reqDelUser(userId: number) {
    return Http.post('/admin/del-back-user/', {
      userId
    })
  }
}
