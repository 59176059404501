
import Vue from 'vue'
import Component from 'vue-class-component'
// import { mapGetters } from 'vuex'
import { Prop, PropSync } from 'vue-property-decorator'
// import WS from '@/apis/WS'
import backUser from '../../../../apis/BackUser'

@Component({
  name: 'BackUsersModal'
})

export default class BackUsersModal extends Vue {
  @PropSync('isShowModal', { type: Boolean }) syncShowModal!: boolean
  @PropSync('needUpdateModal', { type: Boolean }) needUpdateModalSync!: boolean
  @PropSync('idUserDeleteModal', { type: Number }) idUserDelete!: number
  @PropSync('nameUserDeleteModal', { type: String }) nameUserDelete!: string
  @Prop(String) requestId: any

  delUserModal() {
    backUser.reqDelUser(this.idUserDelete)
      .then(({ data }) => {
        if (data.success === true) {
          this.needUpdateModalSync = true
          this.syncShowModal = false
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }
}
